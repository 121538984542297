import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilterBar = _resolveComponent("FilterBar")!
  const _component_WineCard = _resolveComponent("WineCard")!
  const _component_Table = _resolveComponent("Table")!
  const _component_Button = _resolveComponent("Button")!
  const _component_CardButton = _resolveComponent("CardButton")!
  const _component_PageFooter = _resolveComponent("PageFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", { class: "text-start mb-2 mb-md-1" }, " Favorieten ", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "d-none d-md-block text-start mb-5" }, " Wijnen die door u zijn gemarkeerd als favoriet. ", -1)),
    ($setup.wines.length > 0)
      ? (_openBlock(), _createBlock(_component_FilterBar, {
          key: 0,
          count: $setup.total,
          filter: $setup.filter,
          onReset: $setup.onFilterReset,
          onFilter: $setup.onFilterChange,
          onSort: $setup.getWines
        }, null, 8, ["count", "filter", "onReset", "onFilter", "onSort"]))
      : _createCommentVNode("", true),
    ($setup.wines.length > 0)
      ? (_openBlock(), _createBlock(_component_Table, { key: 1 }, {
          items: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.favoriteWines, (wine) => {
              return (_openBlock(), _createBlock(_component_WineCard, {
                key: wine.id,
                wine: wine,
                onWineClick: ($event: any) => ($setup.openWine(wine))
              }, null, 8, ["wine", "onWineClick"]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    ($setup.wines.length > 0 && $setup.total - $setup.currentCount !== 0)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 2,
          text: $setup.buttonText,
          design: "primary",
          onButtonClick: $setup.loadMore
        }, null, 8, ["text", "onButtonClick"]))
      : _createCommentVNode("", true),
    ($setup.wines.length === 0)
      ? (_openBlock(), _createBlock(_component_CardButton, {
          key: 3,
          text: "U heeft nog geen wijnen als favoriet gemarkeerd. Markeer wijnen als favoriet door op de <ion-icon name='heart-outline'></ion-icon> te klikken.",
          buttonText: "Naar assortiment",
          onButtonClick: $setup.navigateToAssortiment
        }, null, 8, ["onButtonClick"]))
      : _createCommentVNode("", true),
    ($setup.wines.length > 0)
      ? (_openBlock(), _createBlock(_component_PageFooter, {
          key: 4,
          class: "d-none d-md-flex",
          button: $setup.button,
          font: "dinpro",
          box: "Bekijk ook uw eerder bestelde wijnen",
          onButtonClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.button.callBack()))
        }, null, 8, ["button"]))
      : _createCommentVNode("", true)
  ]))
}