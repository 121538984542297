
import { plainToClass } from 'class-transformer';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import FilterBar from '@/components/Filter/FilterBar.vue';
import WineCard from '@/components/WineCard.vue';
import Button from '@/shared/components/Button.vue';
import CardButton from '@/shared/components/CardButton.vue';
import PageFooter from '@/shared/components/PageFooter.vue';
import Table from '@/shared/components/Table.vue';
import { Wine } from '@/shared/models/Wine';

export default {
    components: {
        CardButton,
        PageFooter,
        FilterBar,
        Table,
        WineCard,
        Button,
    },
    setup() {
        const wines = ref(Array<Wine>());
        const favoriteWines = computed(() => wines.value.filter((wine: Wine) => wine.is_favorited));
        const filter = ref({});
        const store = useStore();
        const currentPage = ref(1);
        const total = ref(0);
        const currentCount = computed(() => wines.value.length);

        function getWines() {
            currentPage.value = 1;
            store.dispatch('WINES_GET_FAVORITES', { currentPage }).then((res) => {
                filter.value = res.data;
                wines.value = plainToClass(Wine, res.data.wines as Wine[]);
                total.value = res.data.metadata.total;
            });
        }

        getWines();

        function loadMore() {
            currentPage.value += 1;
            store.dispatch('WINES_GET_FAVORITES', { currentPage }).then((res) => {
                filter.value = res.data;
                res.data.wines.forEach((wine: any) => {
                    wines.value.push(plainToClass(Wine, wine));
                });
                total.value = res.data.metadata.total;
            });
        }

        const buttonText = computed(() => `TOON MEER (${total.value - currentCount.value})`);

        const router = useRouter();

        function navigateTo(url: any) {
            window.scrollTo(0, 0);
            router.push(url);
        }

        const button = {
            text: 'Eerder besteld',
            callBack: () => navigateTo({ name: 'OrderedBefore' }),
        };

        function openWine(wine: Wine) {
            const id = String(wine.id);
            router.push({ name: 'AssortimentDetail', params: { id } });
        }

        function navigateToAssortiment() {
            navigateTo('assortiment');
        }

        function onFilterReset(filterReset: string) {
            store.commit('SET_FAVORITES_FILTER', filterReset);
            getWines();
        }

        function onFilterChange(filterChange: string) {
            store.commit('SET_FAVORITES_FILTER', filterChange);
            getWines();
        }

        return {
            button,
            filter,
            getWines,
            wines,
            total,
            currentCount,
            buttonText,
            loadMore,
            openWine,
            navigateToAssortiment,
            favoriteWines,
            onFilterReset,
            onFilterChange,
        };
    },
};
